import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { deleteNota, getEstudianteNotas, getStudenById } from "../../redux/actions";
import { Link, useParams, useNavigate } from "react-router-dom";
import style from "./EstudianteNotas.module.css";
import logoMinedu from "../CertificadoDeEstudio/logoMinedu.jpg"

const EstudianteNotas = () => {
    const componentRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const data = useSelector((state) => state.estudianteNotas);
    const estudiante = useSelector((state) => state.studentById);
    console.log("Estudiante notas:",data)

    useEffect(() => {
        dispatch(getEstudianteNotas(id));
        dispatch(getStudenById(id));
    }, [dispatch, id]);

    function handleLinkClick(id) {
        const userConfirmed = window.confirm("¿Deseas eliminar la nota?");
        if (userConfirmed) {
            dispatch(deleteNota(id));
            navigate();
        }
    }

    // Función para formatear la fecha en "día-mes-año"
    function formatDate(isoDate) {
        const date = new Date(isoDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    let departamento = "";
    switch (estudiante?.id_departamento) {
        case 1:
            departamento = "ADMINISTRACIÓN DE EMPRESAS";
            break;
        case 2:
            departamento = "ADMINISTRACIÓN DE OPERACIONES TURÍSTICAS";
            break;
        case 3:
            departamento = "CONSTRUCCIÓN CIVIL";
            break;
        case 4:
            departamento = "CONTABILIDAD";
            break;
        case 5:
            departamento = "DESARROLLO DE SISTEMAS DE INFORMACIÓN";
            break;
        case 6:
            departamento = "ELECTRICIDAD INDUSTRIAL";
            break;
        case 7:
            departamento = "ENFERMERÍA TÉCNICA";
            break;
        case 8:
            departamento = "MANEJO FORESTAL";
            break;
        case 9:
            departamento = "MECATRÓNICA AUTOMOTRIZ";
            break;
        case 10:
            departamento = "PRODUCCIÓN AGROPECUARIA";
            break;
        case 11:
            departamento = "ASISTENCIA ADMINISTRATIVA";
            break;
        case 12:
            departamento = "ELECTRICIDAD";
            break;
        case 13:
            departamento = "ELECTRÓNICA";
            break;
        case 14:
            departamento = "ELECTROTECNIA INDUSTRIAL";
            break;
        case 15:
            departamento = "GUÍA OFICIAL DE TURISMO";
            break;
        case 16:
            departamento = "ADMINISTRACIÓN";
            break;
        case 17:
            departamento = "COMPUTACIÓN E INFORMÁTICA";
            break;
        case 18:
            departamento = "FORESTAL";
            break;
        case 19:
            departamento = "ADMINISTRACIÓN DE RECURSOS FORESTALES";
            break;
        case 20:
            departamento = "SECRETARIADO EJECUTIVO";
            break;
        default:
            departamento = "";
    }

    return (
        <div className={style.container}>
            <div ref={componentRef} className={style.printcontainer}>
                <Link to="/consultarnotas">
                    <img
                        style={{ marginLeft: "-330px" }}
                        src="https://4.bp.blogspot.com/-ZvuRPEKrgoo/WTtGO6k2PkI/AAAAAAAA9Tc/J0IqI8giwGoVWhWi_Z68UckJqICJIarWwCLcB/s1600/ist-suiza-pucallpa-insignia.jpg"
                        alt="Logo del instituto"
                        height="150px"
                        width="130px"
                    />
                </Link>
                <img
                    style={{ marginLeft: "250px" }}
                    src={logoMinedu}
                    alt="Logo del ministerio no disponible"
                    height="50px"
                    width="250px"
                />

                <h6>INSTITUTO DE EDUCACIÓN SUPERIOR TECNOLÓGICO PÚBLICO</h6>
                <h4>"SUIZA"</h4>
                <br />
                <h1>REPORTE DE CALIFICACIONES</h1>
                <br />       


                

                Que: <h2>{estudiante?.nombres} {estudiante?.apellidos}</h2>

                <table>
                    <tbody>
                        <tr>
                            <td>ha cursado las Unidades Didácticas que se indican en el programa de estudios de:</td>
                        </tr>
                    </tbody>
                </table>

                <h2>{departamento}</h2>

                <table>
                    <tbody>
                        <tr>
                            <td>El resultado final de las evaluaciones fue el siguiente:</td>
                        </tr>
                    </tbody>
                </table>

                <br />

                <div className={style.tablacontainer}>
                    <table className={style.tabla}>
                        <thead>
                            <tr>
                                <th className={style.primerencabezado}>Unidades didácticas</th>
                                <th className={style.tabla}>Número de créditos</th>
                                <th className={style.tabla}>Calificación en números</th>
                                <th className={style.tabla}>Calificación en letras</th>
                                <th className={style.tabla}>Año</th>
                                <th className={style.tabla}>Periodo académico</th>
                                <th className={style.tabla}>Fecha</th>
                                <th className={style.tabla}>Observaciones</th>
                                <th className={style.tabla}>Editar</th>
                                <th className={style.tabla}>Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(item => (
                                <tr key={item.id_asignatura}>
                                    <td className={style.filas}>{item.curso_nombre}</td>
                                    <td className={style.filas2}>{item.creditos}</td>
                                    <td className={style.filas2}>{item.valor_numeros}</td>
                                    <td className={style.filas2}>{item.valor_letras}</td>
                                    <td className={style.filas2}>{item.ciclo}</td>
                                    <td className={style.filas2}>{item.periodo_nombre}</td>
                                    <td className={style.filas2}>{item.fecha}</td>
                                    <td className={style.filas2}>{item.tipo_nota}</td>
                                    <td className={style.filas2}>
                                        <Link className={style.filasEditarEliminar} to={`/editarnota/${item.id_nota}`}>Editar</Link>
                                    </td>
                                    <td className={style.filas2}>
                                        <Link className={style.filasEditarEliminar} to="#" onClick={() => handleLinkClick(item.id_nota)}>Eliminar</Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default EstudianteNotas;
